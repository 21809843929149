import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Alert } from 'antd';
import _ from 'lodash';
import './../../assets/styles.css';
import 'antd/dist/antd.css';
const { TextArea } = Input;


export const Textarea = ({ name, question, hissu_flag, disabled }) => {
    const {control, formState: {errors}} = useFormContext();
    const errname = _.get(errors, name);

    return(
        <div className="top-line">
            <label>
                <span className="question">Q.</span>
                {question}
            </label>
            <div className="side-mage">
                <Controller
                    control={control}
                    name={name}
                    rules={{ required: {value: hissu_flag, message: "入力してください。"}, 
                            maxLength: { value: 20000, message: "20000文字以内で入力してください。" }}}
                    as={<TextArea rows={4} disabled={disabled} />}
                />
            </div>
            <div className="alert-error">
                {errname && <Alert className="alert-error"
                     message={errname.message} type="error" showIcon />} 
            </div>
        </div>
    );
}