import React from "react";
import { Textarea } from './Textarea';
import { Radioes } from './Radioes';
import { Checkboxes } from './Checkboxes';
import "./../../assets/styles.css";


export const Elements = ({ enquete, disabled }) => {
    var options = [];
    var valueArray = "";
    var count = 1;

    // 1:YES/NO 2:ラジオ 3:チェックボックス 4:テキストエリア 
    switch (enquete.type) {
        case 1:
            options = [
                {label: "Yes", value: 1},
                {label: "No", value: 2}
            ]
            return(
                <Radioes 
                    name={'Q'+enquete.order} 
                    question={enquete.question} 
                    hissu_flag={enquete.hissu_flag===1 ? true:false} 
                    options={options}
                    disabled={disabled}
                />
            );

        case 2:
            valueArray = Object.values(enquete)
            for (let i=8; i<14; i++) {
                if (valueArray[i]) {
                    options.push({ label: valueArray[i], value: count++ })
                }
            }
            return(
                <Radioes 
                    name={'Q'+enquete.order} 
                    question={enquete.question} 
                    hissu_flag={enquete.hissu_flag===1 ? true:false} 
                    options={options}
                    disabled={disabled}
                />
            );

        case 3:
            valueArray = Object.values(enquete)
            for (let j=8; j<14; j++) {
                if (valueArray[j]) {
                    options.push({ label: valueArray[j], value: count++ })
                }
            }
            return(
                <Checkboxes 
                    name={'Q'+enquete.order} 
                    question={enquete.question} 
                    hissu_flag={enquete.hissu_flag===1 ? true:false} 
                    options={options}
                    disabled={disabled}
                />
            );
 
        case 4:
            return (
                <Textarea 
                    name={'Q'+enquete.order} 
                    question={enquete.question} 
                    hissu_flag={enquete.hissu_flag===1 ? true:false}
                    disabled={disabled}
                />
            );
    
        default:
            return null;

    }  
    
  }