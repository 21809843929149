import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form/dist/index.ie11';
import { message, Alert } from 'antd'
import { Elements } from './elements/Elements'
import 'antd/dist/antd.css'


export const Eachforms = ({ enquete, skey, pkey, domain }) => {
    // 「質問ごとに送信」の場合は
    // Mcubeアンケート管理(必須項目)に影響せず強制的に必須にします。
    enquete.hissu_flag = 1;

    // デフォルト値
    var defaultValues = {};
    let defaultFlag = false;
    if (enquete.answer_value) {
        defaultValues['Q'+enquete.order] = enquete.answer_value;
        defaultFlag = true;
    } else {
        defaultValues['Q'+enquete.order] = "";
    }
    
    // 登録
    const [isError, setIsError] = useState(false);
    const [processing, setProcessing] = useState(false);
    const onSubmit = async(data) => {
        if (processing) return;
        setProcessing(true);
        const header = {'Content-Type': 'application/json; charset=utf-8', 
            "X-Requested-With": "XMLHttpRequest"}
        enquete.value = Object.values(data)[0];
        enquete.user_key = pkey;
        //console.log("enquete", enquete);
        try {
            const endpoint = domain + '/api/v1/seminars/' + skey +'/answers';
            let result = false;
            if (defaultFlag || methods.formState.isSubmitSuccessful) {
                result = await axios.put(endpoint, [enquete], { headers: header });
            } else {
                result = await axios.post(endpoint, [enquete], { headers: header });
            }
            // サーバ側バリデーション
            if (!_.isEmpty(result.data.validates)) {
                const valid = result.data.validates[0];
                methods.setError('Q'+valid.order, {
                    types: "required", message: valid.message
                })
            }
            // 完了
            if (result.data.message === "success") {
                const key = 'updatable';
                message.loading({ content: 'Loading...', key, style: { marginTop: '25vh' }});
                setTimeout(() => {
                    message.info({
                        content: '回答を受け付けました。',
                        key, duration: 2,
                        className: 'custom-class',
                        style: { marginTop: '25vh' }
                    })
                }, 500);
            }
            //console.log("result", result.data);
        } catch(e) {
            setIsError(true);
        } finally {
            setProcessing(false);
        }
    }

    // フォーム
    const methods = useForm({defaultValues});
    const args = new URL(document.URL);
    const bname = "回答する";
    if (isError) {
        return (
            <div className="top-line">
                <Alert
                    message="アクセスエラーが発生しました"
                    description="しばらく時間をおいてから、もう一度お試しください。
                        問題が継続する場合は、管理者までお問い合わせください。"
                    type="error"
                />
                <hr/>
                <a href={args}>[戻る]</a>
            </div>
        );
    }

    // 非公開は何も表示しない
    if (enquete.shimekiri_flag === 2) {
        return (<></>);
    }
    
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {defaultFlag || methods.formState.isSubmitSuccessful || processing  ? (
                        <div>
                            <Elements enquete={enquete} disabled={true} />
                            <input type="submit" value="回答済み" disabled />
                        </div>
                    ):(
                        <div>
                            <Elements enquete={enquete} disabled={false} />
                            <input type="submit" value={bname} />
                        </div>
                    )}
                </form>
            </FormProvider>
        </>
    );

}