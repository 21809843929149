import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Eachforms } from './components/Eachforms';
import { Error } from './shares/Error';
import { Loading } from './shares/Loading';
import './assets/styles.css';


export default function App() {
    // URLパラメータ取得
	const args = new URL(document.URL);
	const [pkey] = useState(args.searchParams.get('p'));
	const [skey] = useState(args.searchParams.get('s'));
    let domain = args.origin;
    // ※localhostの例外処理
    if (_.includes(domain, 'localhost')) {
        domain = "https://quick-plus-stg.m3dc-vs.com";
    }

    return (
        <Router>
            <Switch>
                <Route exact path='/enquete/'>
                    <Home skey={skey} pkey={pkey} domain={domain} />
                </Route>
                <Route>
                    <Error title="NotFound" body="ページが見つかりません。" />
                </Route>
            </Switch>
        </Router>
    );
}

const Home = ({ skey, pkey, domain }) => {
    
    // データ取得
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async() => {        
            try {
                let time = new Date().getTime();
                const endpoint = domain + '/api/v1/seminars/' 
                    + skey +'/answers?user_key='+ pkey +'&timestamp='+ time;
                const result = await axios.get(endpoint);
                //console.log(result.data)
                setItems(result.data);
            } catch(e) {
                return (
                    <Error
                        title="アクセスエラーが発生しております" 
                        body="しばらく時間をおいてから、再度表示してください。
                            表示できない場合は、管理者までお問い合わせください。" 
                    />
                );
            } finally {
                setIsLoading(false);
            }
        }
        //初回はすぐデータを取得、次回以降15秒単位で実行する
        fetchData();
        setInterval(fetchData, 15000);
    },[pkey,skey,domain])

    // アンケートフォーム
    const {seminars, enquetes} = items ?? {};
    if (isLoading) {
        return <Loading />;
    }
    if (!seminars) {
        return (
            <Error
                title="セミナー情報が取得できません" 
                body="URL不備があります、アンケートにアクセスし直してください。
                    問題が継続する場合は、管理者までお問い合わせください。" 
            />
        );
    }
    if (!enquetes || enquetes.length === 0) {
        return (
            <Error
                title="アンケート情報が取得できません" 
                body="アンケートが登録されていない可能性がございます。
                    しばらく時間をおいてから、再度表示してください。
                    問題が継続する場合は、管理者までお問い合わせください。" 
            />
        );
    }

    const seminar = seminars[0]
    // 非公開の場合は何も表示しない
    if (seminar.public_status === 2) {
        return (<></>);      
    }

    return (
        <div className="App">
            <h2>アンケート</h2>
            <p>ご視聴中、リアルタイムで回答できるアンケートです。</p>
            {enquetes.map((enquete, index) =>
                <Eachforms 
                    key={index} 
                    enquete={enquete} 
                    skey={skey}
                    pkey={pkey}
                    domain={domain}
                />
            )}
        </div>
    );
}