import React from 'react';
import { Spin } from 'antd';
import './../assets/styles.css';

export const Loading = () => {
    return (
        <div className="central">
          <Spin size="large" tip="Loading..."></Spin>
        </div>
    );
}